import React from "react";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import ServiceSidebar from "../components/ServiceDetails/ServiceSidebar";
import RelatedServices from "../components/ServiceDetails/RelatedServices";

const Details = ({ data }) => {
  const { title, longText } = data.solution;
  return (
    <Layout>
      <Navbar />

      <PageBanner
        pageTitle={title}
        homePageText="Home"
        homePageUrl="/"
        activePageText={title}
      />

      <section className="services-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              {/*<ReactMarkdown source={longText} />*/}
              <div dangerouslySetInnerHTML={{ __html: longText }}></div>
            </div>

            <div className="col-lg-4 col-md-12">
              <ServiceSidebar />
            </div>
          </div>
        </div>
      </section>

      <RelatedServices />

      <Footer />
    </Layout>
  );
};

export const query = graphql`
    query GetSingleServiceSolution($slug: String, $language: String!) {
        solution: strapiServiceSolution(slug: { eq: $slug }) {
            title
            longText
        }
        locales: allLocale(
            filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
          ) {
            edges {
              node {
                ns
                data
                language
              }
            }
          }
    }
`;

export default Details;

